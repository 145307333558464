import { createContext, useEffect, useReducer } from "react"
import { decodeToken, isValidToken, setLocal } from "utils/jwt"
import axios from "utils/axios"
import { HttpStatusCode } from "axios"
import PropTypes from "prop-types"

const initialState = { isAuthenticated: false, isInitialized: false, user: null }

const AuthContext = createContext({
  ...initialState,
  method: "jwt",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve()
})

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload
    return { ...state, isAuthenticated, isInitialized: true, user }
  },
  LOGIN: (state, action) => {
    const { user } = action.payload
    return { ...state, isAuthenticated: true, user }
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  })
}
const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state)

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState)

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.status === HttpStatusCode.Unauthorized) {
        setLocal(null)
        dispatch({ type: "LOGOUT" })
      }

      return Promise.reject(error)
    }
  )

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken")

        if (accessToken && isValidToken(accessToken)) {
          setLocal(accessToken)
          const user = decodeToken(accessToken)
          if (user) return dispatch({ type: "INITIALIZE", payload: { isAuthenticated: true, user } })
          return dispatch({ type: "INITIALIZE", payload: { isAuthenticated: false, user: null } })
        }
        return dispatch({ type: "INITIALIZE", payload: { isAuthenticated: false, user: null } })
      } catch {
        return dispatch({ type: "INITIALIZE", payload: { isAuthenticated: false, user: null } })
      }
    }
    initialize()
  }, [])

  const login = async (payload) => {
    const response = await axios.post("/auth/login", payload, {
      headers: {
        "Content-Type": "application/json"
      }
    })
    const { token: accessToken } = response.data
    const user = decodeToken(accessToken)

    setLocal(accessToken)
    dispatch({ type: "LOGIN", payload: { user } })
  }

  const logout = async () => {
    await axios.post("/auth/logout")
    setLocal(null)
    dispatch({ type: "LOGOUT" })
  }

  return <AuthContext.Provider value={{ ...state, method: "jwt", login, logout }}>{children}</AuthContext.Provider>
}

AuthProvider.propTypes = {
  children: PropTypes.node
}

export { AuthProvider, AuthContext }
