import jwtDecode from "jwt-decode"
import axios from "./axios"

export const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false
  }

  const decode = jwtDecode(accessToken)
  const currentTime = Date.now() / 1000
  const expiredTime = new Date(decode.expired_at).getTime()

  return expiredTime > currentTime
}

export const setLocal = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken)
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
  } else {
    localStorage.removeItem("accessToken")
    delete axios.defaults.headers.common.Authorization
  }
}

export const decodeToken = (accessToken) => {
  if (!accessToken) return false

  const user = jwtDecode(accessToken)
  return user
}
