import LoadingPage from "components/LoadingPage"
import useAuth from "hooks/useAuth"
import Login from "pages/auth/Login"
import { useState } from "react"
import { useLocation, Navigate } from "react-router-dom"
import PropTypes from "prop-types"

export default function AuthGuard({ children }) {
  const { isAuthenticated, isInitialized } = useAuth()
  const { pathName } = useLocation()
  const [requestLocation, setRequestLocation] = useState(null)
  if (!isInitialized) {
    return <LoadingPage />
  }
  if (!isAuthenticated) {
    if (requestLocation !== pathName) {
      setRequestLocation(pathName)
    }
    return <Login />
  }
  if (requestLocation && pathName !== requestLocation) {
    setRequestLocation(null)
    return <Navigate to={requestLocation} />
  }

  return <>{children}</>
}

AuthGuard.propTypes = {
  children: PropTypes.node
}
