import { Layout, Space, Dropdown, Button, Image } from "antd"
import { CaretDownOutlined } from "@ant-design/icons"
import styled from "styled-components"
import useLocales from "hooks/useLocales"

import MainLogo from "../../../assets/mainlogo.png"

const { Header } = Layout

const Toolbar = styled.div({
  display: "flex",
  width: "100%",
  alignItems: "center",
  position: "relative",
  minHeight: "56px",
  height: "100%"
})

const Account = styled(Space)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-end",
  flexGrow: 1,
  cursor: "pointer"
})

const ButtonContainer = styled(Space)({})
const LogoContent = styled.div({
  alignItems: "center"
})

export default function ServicesHeader() {
  const { onChangeLang, currentLang, allLang } = useLocales()

  const language = allLang.map((item) => {
    return {
      label: item.label,
      icon: item.icon,
      onClick: () => onChangeLang(item.value)
    }
  })

  const item = language

  return (
    <Header>
      <Toolbar>
        <LogoContent>
          <Image preview={false} src={MainLogo} alt="main logo" style={{ width: "160px", height: "auto" }} />
        </LogoContent>
        <Account>
          <Dropdown
            menu={{
              items: item
            }}
            trigger={["click"]}
          >
            <Button type="text" style={{ marginTop: "15px", lineHeight: "15px" }}>
              <ButtonContainer>
                {currentLang.icon}
                {`${currentLang.label}`}
                <CaretDownOutlined />
              </ButtonContainer>
            </Button>
          </Dropdown>
        </Account>
      </Toolbar>
    </Header>
  )
}

ServicesHeader.propTypes = {}
