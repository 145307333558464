import LoadingPage from "components/LoadingPage"
import useAuth from "hooks/useAuth"
import { Navigate } from "react-router-dom"
import PropTypes from "prop-types"

export default function GuestGuard({ children }) {
  const { isAuthenticated, isInitialized } = useAuth()

  if (!isInitialized) {
    return <LoadingPage />
  }

  if (isAuthenticated) {
    return <Navigate to="/main/card" />
  }

  return <>{children}</>
}

GuestGuard.propTypes = {
  children: PropTypes.node
}
