import { useTranslation } from "react-i18next"
import { LANGS } from "configs"

export default function useLocales() {
  const { i18n, t: translate } = useTranslation()
  const langStorage = localStorage.getItem("i18nextLng")
  const currentLang = LANGS.find((lang) => lang.value === langStorage) || LANGS[0]

  const handleChangeLanguage = (newLang) => {
    i18n.changeLanguage(newLang)
    localStorage.setItem("i18nextLng", newLang)
  }

  return {
    onChangeLang: handleChangeLanguage,
    translate,
    currentLang,
    allLang: LANGS
  }
}
