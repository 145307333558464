import PropTypes from "prop-types"
import { ConfigProvider } from "antd"
import ComponentsOverride from "./overrides"
import palette from "./palette"
import useLocales from "hooks/useLocales"

export default function ThemeProvider({ children, locale, ...other }) {
  const { currentLang } = useLocales()

  const theme = {
    token: {
      ...palette.light,
      sizeStep: 4,
      sizeUnit: 4,
      borderRadius: 8,
      controlHeight: 32,
      fontSize: 14,
      lineType: "solid",
      lineWidth: 1
    }
  }

  theme.components = ComponentsOverride({ ...theme, palette })

  return (
    <ConfigProvider theme={theme} locale={currentLang.systemValue} {...other}>
      {children}
    </ConfigProvider>
  )
}

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
  locale: PropTypes.object
}
