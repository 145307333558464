import PropTypes from "prop-types"
import { useFormContext, Controller } from "react-hook-form"
import styled from "styled-components"
import { TimePicker, Form, theme } from "antd"
import FloatInput from "./components/FloatInput"

const { useToken } = theme
const { RangePicker: AntdRangePicker } = TimePicker

const RangePicker = styled(AntdRangePicker)(({ token: { TimePicker } }) => ({
  font: "inherit",
  letterSpacing: "inherit",
  color: "currentcolor",
  border: "0px",
  boxSizing: "content-box",
  background: "none",
  lineHeight: TimePicker.lineHeight,
  margin: "0px",
  minWidth: "0px",
  width: "100%",
  animationDuration: "10ms",
  backgroundColor: "#FFF",
  ":focus": {
    boxShadow: "none",
    borderColor: "transparent"
  }
}))

export default function RHFTimePickerRange({ name, placeholder, required, size = "middle", prefix, ...other }) {
  const { control } = useFormContext()
  const { token } = useToken()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Form.Item required={required} validateStatus={!!error && "error"} help={error?.message}>
          <FloatInput
            required={required}
            placeholder={placeholder}
            value={field.value}
            name={name}
            prefix={prefix}
            error={!!error}
            size={size}
          >
            <RangePicker placeholder={null} size={size} token={token} {...field} {...other} />
          </FloatInput>
        </Form.Item>
      )}
    />
  )
}

RHFTimePickerRange.propTypes = {
  name: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  size: PropTypes.string,
  prefix: PropTypes.node,
  placeholder: PropTypes.string
}
