const checkPermissions = (data, permission_key, actions) => {
  if (!data) return false

  const isAdmin = data?.filter((ele) => {
    return ele.key === "super-administrator" && ele.action === "admin"
  })

  if (isAdmin?.length > 0) {
    return true
  }

  let hasPermission = true

  const permissions = data?.filter((ele) => {
    return ele.key === permission_key && ele.action === actions
  })

  if (permissions?.length === 0) {
    hasPermission = false
  }

  return hasPermission
}

export default checkPermissions
