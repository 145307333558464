import { Outlet } from "react-router-dom"
import { Layout } from "antd"
import Header from "./components/Header"
import Sidebar from "./components/Sidebar"
import useCollapse from "hooks/useCollapse"
import styled from "styled-components"

const ContentStyle = styled.div({
  padding: "24px 24px"
})

const { Content } = Layout

export default function MainLayout() {
  const { isCollapse, onToggle } = useCollapse()

  const handleCollapsed = () => {
    onToggle()
  }

  return (
    <Layout>
      <Sidebar collapsed={isCollapse} />
      <Layout>
        <Header collapsed={isCollapse} onCollapsed={handleCollapsed} />
        <Content>
          <ContentStyle>
            <Outlet />
          </ContentStyle>
        </Content>
      </Layout>
    </Layout>
  )
}
