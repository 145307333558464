import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"

import thLocales from "./th.json"
import enLocales from "./en.json"

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      th: { translations: thLocales },
      en: { translations: enLocales }
    },
    lng: localStorage.getItem("i18nextLng") || "th",
    fallbackLng: "th",
    debug: false,
    ns: ["translations"],
    interpolation: {
      escapeValue: false
    }
  })

export default i18n
