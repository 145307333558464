import PropTypes from "prop-types"
import { useFormContext, Controller } from "react-hook-form"
import styled from "styled-components"
import { Form, AutoComplete as AntAutoComplete, theme } from "antd"
import FloatInput from "./components/FloatInput"

const { useToken } = theme

const AutoComplete = styled(AntAutoComplete)(({ token: { Input } }) => ({
  font: "inherit",
  letterSpacing: "inherit",
  color: "currentcolor",
  border: "0px",
  boxSizing: "content-box",
  background: "none",
  lineHeight: Input.lineHeight,
  margin: "0px",
  minWidth: "0px",
  width: "100%",
  animationDuration: "10ms",
  backgroundColor: "#FFF",
  ":focus": {
    boxShadow: "none",
    borderColor: "transparent"
  },
  ".ant-select-selection-search-input": {
    fontSize: "16px"
  }
}))

export default function RHFAutocomplete({ name, placeholder, required, type, size = "middle", ...other }) {
  const { control } = useFormContext()
  const { token } = useToken()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Form.Item required={required} validateStatus={!!error && "error"} help={error?.message}>
          <FloatInput
            required={required}
            placeholder={placeholder}
            value={field.value}
            name={name}
            error={!!error}
            size={size}
          >
            <AutoComplete bordered={false} token={token} size={size} {...field} {...other} />
          </FloatInput>
        </Form.Item>
      )}
    />
  )
}

RHFAutocomplete.propTypes = {
  name: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  size: PropTypes.string,
  placeholder: PropTypes.string
}
