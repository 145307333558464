import { createSlice } from "@reduxjs/toolkit"

export const collapseSlice = createSlice({
  name: "collapse",
  initialState: {
    collapsed: false
  },
  reducers: {
    toggle: (state) => {
      state.collapsed = !state.collapsed
    }
  }
})

// Action creators are generated for each case reducer function
export const { toggle } = collapseSlice.actions

export default collapseSlice.reducer
