import { Outlet } from "react-router-dom"
import { Layout } from "antd"
import Header from "./components/Header"
import styled from "styled-components"

const ContentStyle = styled.div({
  padding: "24px 24px"
})

const { Content } = Layout

export default function ServicesLayout() {
  return (
    <Layout>
      <Layout>
        <Header />
        <Content>
          <ContentStyle>
            <Outlet />
          </ContentStyle>
        </Content>
      </Layout>
    </Layout>
  )
}
