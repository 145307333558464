import App from "./App"
import Affix from "./Affix"
import Anchor from "./Anchor"
import AutoComplete from "./AutoComplete"
import Avatar from "./Avatar"
import BackTop from "./BackTop"
import Badge from "./Badge"
import Breadcrumb from "./Breadcrumb"
import Button from "./Button"
import Calendar from "./Calendar"
import Card from "./Card"
import Carousel from "./Carousel"
import Cascader from "./Cascader"
import Checkbox from "./Checkbox"
import Col from "./Col"
import Collapse from "./Collapse"
import DatePicker from "./DatePicker"
import Descriptions from "./Descriptions"
import Divider from "./Divider"
import Drawer from "./Drawer"
import Dropdown from "./Dropdown"
import Empty from "./Empty"
import FloatButton from "./FloatButton"
import Form from "./Form"
import Grid from "./Grid"
import Icon from "./Icon"
import Image from "./Image"
import Input from "./Input"
import InputNumber from "./InputNumber"
import Layout from "./Layout"
import List from "./List"
import Mentions from "./Mentions"
import Menu from "./Menu"
import Modal from "./Modal"
import Pagination from "./Pagination"
import Popconfirm from "./Popconfirm"
import Popover from "./Popover"
import Progress from "./Progress"
import QRCode from "./QRCode"
import Radio from "./Radio"
import Rate from "./Rate"
import Result from "./Result"
import Row from "./Row"
import Segmented from "./Segmented"
import Select from "./Select"
import Skeleton from "./Skeleton"
import Slider from "./Slider"
import Space from "./Space"
import Spin from "./Spin"
import Steps from "./Steps"
import Switch from "./Switch"
import Table from "./Table"
import Tabs from "./Tabs"
import Timeline from "./Timeline"
import TimePicker from "./TimePicker"
import Tooltip from "./Tooltip"
import Tour from "./Tour"
import Transfer from "./Transfer"
import Tree from "./Tree"
import TreeSelect from "./TreeSelect"
import Typography from "./Typography"
import Upload from "./Upload"
import Watermark from "./Watermark"

export default function ComponentsOverride(theme) {
  return {
    Affix: Affix(theme),
    Anchor: Anchor(theme),
    App: App(theme),
    AutoComplete: AutoComplete(theme),
    Avatar: Avatar(theme),
    BackTop: BackTop(theme),
    Badge: Badge(theme),
    Breadcrumb: Breadcrumb(theme),
    Button: Button(theme),
    Calendar: Calendar(theme),
    Card: Card(theme),
    Carousel: Carousel(theme),
    Cascader: Cascader(theme),
    Checkbox: Checkbox(theme),
    Col: Col(theme),
    Collapse: Collapse(theme),
    DatePicker: DatePicker(theme),
    Descriptions: Descriptions(theme),
    Divider: Divider(theme),
    Drawer: Drawer(theme),
    Dropdown: Dropdown(theme),
    Empty: Empty(theme),
    FloatButton: FloatButton(theme),
    Form: Form(theme),
    Grid: Grid(theme),
    Icon: Icon(theme),
    Image: Image(theme),
    Input: Input(theme),
    InputNumber: InputNumber(theme),
    Layout: Layout(theme),
    List: List(theme),
    Mentions: Mentions(theme),
    Menu: Menu(theme),
    Modal: Modal(theme),
    Pagination: Pagination(theme),
    Popconfirm: Popconfirm(theme),
    Popover: Popover(theme),
    Progress: Progress(theme),
    QRCode: QRCode(theme),
    Radio: Radio(theme),
    Rate: Rate(theme),
    Result: Result(theme),
    Row: Row(theme),
    Segmented: Segmented(theme),
    Select: Select(theme),
    Skeleton: Skeleton(theme),
    Slider: Slider(theme),
    Space: Space(theme),
    Spin: Spin(theme),
    Steps: Steps(theme),
    Switch: Switch(theme),
    Table: Table(theme),
    Tabs: Tabs(theme),
    Timeline: Timeline(theme),
    TimePicker: TimePicker(theme),
    Tooltip: Tooltip(theme),
    Tour: Tour(theme),
    Transfer: Transfer(theme),
    Tree: Tree(theme),
    TreeSelect: TreeSelect(theme),
    Typography: Typography(theme),
    Upload: Upload(theme),
    Watermark: Watermark(theme)
  }
}
