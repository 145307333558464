import React, { useEffect, useState } from "react"
import useLocales from "hooks/useLocales"
import useAuth from "hooks/useAuth"
import checkPermissions from "helper/checkPermissions"
import PropTypes from "prop-types"
import { Layout, Menu as AntdMenu, theme, Image } from "antd"
import styled from "styled-components"
import { NAV_ITEM } from "configs"
import { useLocation, Link } from "react-router-dom"
import MainLogo from "../../../assets/mainlogo.png"
import MiniLogo from "assets/sublogo.png"
import useCollapse from "hooks/useCollapse"
import Icon from "@ant-design/icons"
import { ReactComponent as VehicleIcon } from "assets/icon/vehicle.svg"

const { Sider } = Layout
const { useToken } = theme

const LogoContent = styled.div({
  alignItems: "center"
})

export default function Sidebar({ collapsed }) {
  const { user } = useAuth()
  const { isCollapse } = useCollapse()
  const location = useLocation()
  const { token } = useToken()
  const { translate } = useLocales()

  const Menu = styled(AntdMenu)(({ token }) => ({
    border: "none !important",
    borderInline: "none !important",
    margin: "24px 0px",
    "&& .ant-menu-item-selected": {
      // backgroundColor: token.colorPrimary,
      color: collapsed ? token.colorPrimary : token.colorTextBase,
      borderRadius: "0px 100px 100px 0px"
    }
    // "&& .ant-menu-item:hover": {
    //   borderRadius: "0px 100px 100px 0px"
    // }
  }))
  const [navbar, setNavbar] = useState([])
  useEffect(() => {
    const navbar = checkPermissionNavbar()
    setNavbar(navbar)
  }, [])

  const sidebarNavFlat = NAV_ITEM.reduce(
    (result, current) => result.concat(current.children && current.children.length > 0 ? current.children : current),
    []
  )

  const currentMenuItem = sidebarNavFlat.find(({ url }) => {
    // find url match with location.pathname include sub path
    return url === location.pathname || location.pathname.includes(url)
  })
  const defaultSelectedKeys = currentMenuItem ? [currentMenuItem.key] : []

  const flattenNavItems = (navItems) => {
    return navItems.reduce((result, item) => {
      if (item.type === "group") {
        result.push(...item.children)
      } else {
        result.push(item)
      }
      return result
    }, [])
  }

  const getChildren = (nav) => ({
    key: nav.key,
    label: nav?.children?.length ? translate(nav.title) : <Link to={nav.url || ""}>{translate(nav.title)}</Link>,
    title: translate(nav.title),
    icon: nav.icon,
    children: nav?.children?.map((children) => getChildren(children))
  })

  const checkPermissionNavbar = () => {
    const navItem = [...NAV_ITEM]

    const navbar = navItem.map((item) => {
      const menu = item.children.filter((menu) => {
        const menu_name = menu.key
        const canView = checkPermissions(user?.permission, menu_name, "view")
        return canView
      })

      return { ...item, children: menu }
    })

    // remove emthy children
    const filterNavbar = navbar.filter((item) => item.children.length > 0)

    return filterNavbar
  }

  return (
    <Sider theme="light" trigger={null} collapsible collapsed={collapsed}>
      <LogoContent>
        <Image
          preview={false}
          src={isCollapse ? MiniLogo : MainLogo}
          alt="main logo"
          style={{ width: "80%", height: "auto", marginLeft: "7px", marginTop: "15px" }}
        />
      </LogoContent>

      <Icon component={VehicleIcon} style={{ display: "none", color: token.colorPrimary }} />

      <Menu
        token={token}
        defaultSelectedKeys={defaultSelectedKeys}
        items={
          !collapsed
            ? navbar.map((nav) => ({
                key: nav.key,
                title: translate(nav.title),
                label: nav?.children?.length ? (
                  translate(nav.title)
                ) : (
                  <Link to={nav.url || ""}>{translate(nav.title)}</Link>
                ),
                icon: nav.icon,
                type: nav?.type,
                children: nav?.children?.map((children) => getChildren(children))
              }))
            : flattenNavItems(navbar).map((nav) => ({
                key: nav.key,
                title: translate(nav.title),
                label: nav?.children?.length ? (
                  translate(nav.title)
                ) : (
                  <Link to={nav.url || ""}>{translate(nav.title)}</Link>
                ),
                icon: nav.icon,
                type: nav?.type,
                children: nav?.children?.map((children) => getChildren(children))
              }))
        }
        mode="inline"
      />
      {/* {NAV_ITEM.map((item) =>
          !collapsed ? (
            <Menu.ItemGroup title={item.title} key={item.key}>
              {item.children.map(({ title, key, icon, children, url }) => (
                <Menu.Item key={key} icon={createElement(Icon, { component: icon })}>
                  {children ? { title } : <Link to={url}>{title}</Link>}
                </Menu.Item>
              ))}
            </Menu.ItemGroup>
          ) : (
            <>
              {item.children.map(({ title, key, icon, children, url }) => (
                <Menu.Item key={key} icon={createElement(Icon, { src: icon })}>
                  {children ? { title } : <Link to={url}>{title}</Link>}
                </Menu.Item>
              ))}
              <Menu.Divider />
            </>
          )
        )} */}
      {/* </Menu> */}
    </Sider>
  )
}

Sidebar.propTypes = {
  collapsed: PropTypes.bool
}
