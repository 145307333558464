// Lib
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import "dayjs/locale/th"

// Router
import { Router } from "routes"

// Themes
import ThemeProvider from "themes"

dayjs.extend(relativeTime)
dayjs.locale("th")

function App() {
  return (
    <ThemeProvider>
      <Router />
    </ThemeProvider>
  )
}

export default App
