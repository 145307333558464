import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { theme } from "antd"

const { useToken } = theme

const FloatLabel = styled("div")({
  position: "relative"
})

const FormControl = styled("div")(() => ({
  display: "inline-flex",
  flexDirection: "column",
  position: "relative",
  minWidth: "0px",
  padding: "0px",
  margin: "0px",
  border: "0px",
  verticalAlign: "top",
  width: "100%"
}))

const Label = styled("label")(({ isOccupied, token: { colorActiveField, colorError }, focus, size, error }) => {
  const transformFontSize = {
    small: "8px",
    middle: "12px",
    large: "16px"
  }

  return {
    fontSize: "1rem",
    fontWeight: 400,
    letterSpacing: "0.00938em",
    padding: "0px",
    display: "block",
    transformOrigin: "left top",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    position: "absolute",
    left: "0px",
    top: "0px",
    maxWidth: "calc(100% - 24px)",
    transition:
      "color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms",
    zIndex: 10,
    transform: `translate(14px, ${transformFontSize[size]}) scale(1)`,
    pointerEvents: "none",
    color: error ? colorError : "rgba(0, 0, 0, 0.23)",
    ...(isOccupied && {
      maxWidth: "calc(133% - 32px)",
      transform: "translate(14px, -9px) scale(0.78)",
      pointerEvents: "auto",
      userSelect: "none",
      color: error ? colorError : "rgba(0, 0, 0, 0.4)",
      ...(focus && {
        color: error ? colorError : colorActiveField
      })
    })
  }
})

const InputContainer = styled("div")({
  fontWeight: 400,
  fontSize: "1rem",
  lineHeight: "1.4375em",
  letterSpacing: "0.00938em",
  color: "rgba(0, 0, 0, 0.87)",
  boxSizing: "border-box",
  cursor: "text",
  display: "inline-flex",
  alignItems: "center",
  position: "relative",
  borderRadius: "inherit",
  minHeight: "50px !important"
})

const Fieldset = styled("fieldset")(({ token: { colorActiveField, colorError }, focus, error }) => ({
  textAlign: "left",
  position: "absolute",
  inset: "-5px 0px 0px",
  margin: "0px",
  padding: "0px 8px",
  pointerEvents: "none",
  borderRadius: "10px",
  borderStyle: "solid",
  borderWidth: "1px",
  overflow: "hidden",
  minWidth: "0%",
  borderColor: error ? colorError : "rgba(0, 0, 0, 0.23)",
  zIndex: 10,
  ...(focus && {
    borderColor: error ? colorError : colorActiveField
  })
}))

const Legend = styled("legend")(({ isOccupied }) => ({
  float: "unset",
  width: "auto",
  overflow: "hidden",
  display: "block",
  padding: "0",
  height: "11px",
  fontSize: "0.75em",
  visibility: "hidden",
  maxWidth: "0.01px",
  transition: "max-width 50ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
  whiteSpace: "nowrap",
  ...(isOccupied && {
    maxWidth: "100%",
    transition: "max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms"
  }),
  "&>span": {
    paddingLeft: "5px",
    paddingRight: "5px",
    display: "inline-block",
    opacity: "0",
    visibility: "visible"
  }
}))

export default function FloatInput({ children, value, required, placeholder, name, prefix, size = "middle", error }) {
  const [focus, setFocus] = useState(false)
  const { token } = useToken()

  const isOccupied = focus || (value && value.length !== 0) || prefix
  const requiredMark = required ? <span className="text-danger">*</span> : null

  return (
    <FloatLabel onBlur={() => setFocus(false)} onFocus={() => setFocus(true)}>
      <FormControl error={error}>
        <Label isOccupied={isOccupied} focus={focus} token={token} htmlFor={name} size={size} error={error}>
          {placeholder} {requiredMark}
        </Label>
        <InputContainer>
          {children}
          <Fieldset isOccupied={isOccupied} focus={focus} token={token} error={error}>
            <Legend isOccupied={isOccupied}>
              <span>
                {placeholder} {requiredMark}
              </span>
            </Legend>
          </Fieldset>
        </InputContainer>
      </FormControl>
    </FloatLabel>
  )
}

FloatInput.propTypes = {
  value: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  children: PropTypes.node,
  size: PropTypes.string,
  error: PropTypes.bool,
  prefix: PropTypes.element
}
