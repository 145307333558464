// Lib
import React from "react"
import ReactDOM from "react-dom"
import { Provider as ReduxProvider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import { HelmetProvider } from "react-helmet-async"
import { QueryClientProvider, QueryClient } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { CollapseProvider } from "context/CollapseContext"
import { AuthProvider } from "context/JWTContext"

import { store } from "stores"
import reportWebVitals from "reportWebVitals"
import App from "App"

import "./locales/i18n"

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false
    }
  }
})

ReactDOM.render(
  <AuthProvider>
    <HelmetProvider>
      <ReduxProvider store={store}>
        <QueryClientProvider client={queryClient}>
          <CollapseProvider>
            <BrowserRouter>
              <App />
              <ReactQueryDevtools initialIsOpen={false} />
            </BrowserRouter>
          </CollapseProvider>
        </QueryClientProvider>
      </ReduxProvider>
    </HelmetProvider>
  </AuthProvider>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
