import React from "react"
import { Image, theme } from "antd"
import styled from "styled-components"
import Page from "components/Login"
import Form from "./components/Form"
import LoginLogo from "assets/logologin.png"
import useLocales from "hooks/useLocales"

const Container = styled("main")({
  height: "100%",
  display: "flex",
  position: "relative",
  minHeight: "100vh"
})

const WrapperImage = styled("div")(({ backgroundColor }) => ({
  display: "flex",
  flexGrow: 1,
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  backgroundColor,
  position: "relative"
}))

const BoxImage = styled("div")({
  lineHeight: 1,
  display: "block",
  overflow: "hidden",
  position: "relative",
  maxWidth: "250px"
})

const FormWrapper = styled("div")({
  width: "60%",
  display: "flex",
  margin: "auto",
  justifyContent: "center",
  flexShrink: 0
})

const { useToken } = theme

export default function Login() {
  const { translate } = useLocales()
  const {
    token: { colorPrimary }
  } = useToken()

  return (
    <Page title={translate("l.login")}>
      <Container>
        <WrapperImage backgroundColor={colorPrimary}>
          <BoxImage>
            <Image preview={false} src={LoginLogo} style={{ width: "100%", height: "100%" }} alt="logo-login" />
          </BoxImage>
        </WrapperImage>
        <FormWrapper>
          <Form />
        </FormWrapper>
      </Container>
    </Page>
  )
}
