const PRIMARY = "#EE6C4D"
const INFO = "#52B5D5"
const SUCCESS = "#77C7A8"
const WARNING = "#FFC136"
const ERROR = "#FF3664"
const TEXT = "#001735"
const TEXT_SECONDARY = "#bbc5d5"
const BACKGROUND = "#FFF"
const DISABLEDBACKGROUND = "#FBFBFC"
const DISABLEDTEXT = "#C2C9D1"
const NOTIFYSUCCESSBG = "#D9FBDF"
const NOTIFYERRORBG = "#FAF0EA"
const NOTIFYWARNINGBG = "#FFF4CE"
const NOTIFYINFOBG = "#E7EEFA"

const NOTIFYSUCCESSTEXT = "#3CB381"
const NOTIFYERRORTEXT = "#EE6C4D"
const NOTIFYWARNINGTEXT = "#FEA92C"
const NOTIFYINFOTEXT = "#11B9D7"

const ACTIVEFIELD = "#2B3240"

const palette = {
  light: {
    colorSuccess: SUCCESS,
    colorWarning: WARNING,
    colorError: ERROR,
    colorPrimary: PRIMARY,
    colorInfo: INFO,
    colorTextBase: TEXT,
    colorTextSecondary: TEXT_SECONDARY,
    colorBgBase: BACKGROUND,
    colorDisabledBg: DISABLEDBACKGROUND,
    colorDisabledText: DISABLEDTEXT,

    colorNotifySuccessBg: NOTIFYSUCCESSBG,
    colorNotifyErrorBg: NOTIFYERRORBG,
    colorNotifyWarningBg: NOTIFYWARNINGBG,
    colorNotifyInfoBg: NOTIFYINFOBG,

    colorNotifySuccessText: NOTIFYSUCCESSTEXT,
    colorNotifyErrorText: NOTIFYERRORTEXT,
    colorNotifyWarningText: NOTIFYWARNINGTEXT,
    colorNotifyInfoText: NOTIFYINFOTEXT,

    colorActiveField: ACTIVEFIELD
  }
}

export default palette
