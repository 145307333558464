export default function Table() {
  return {
    // controlItemBgActive,
    // controlItemBgActiveHover,
    // colorTextPlaceholder: palette.light.colorError,
    // colorTextHeading: palette.light.colorError,
    // colorSplit: palette.light.colorError,
    // colorBorderSecondary: palette.light.colorError,
    fontSize: 14,
    // padding,
    // paddingXS: 50,
    // paddingSM: 50,
    // controlHeight: 50,
    // colorFillAlter: palette.light.colorError,
    // colorIcon: palette.light.colorError,
    // colorIconHover: palette.light.colorError,
    opacityLoading: 1,
    // colorBgContainer: palette.light.colorSuccess,
    borderRadiusLG: 8
    // colorFillContent: palette.light.colorError,
    // colorFillSecondary: palette.light.colorError
  }
}
