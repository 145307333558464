import PropTypes from "prop-types"
import { useFormContext, Controller } from "react-hook-form"
import { Upload, Form } from "antd"

export default function RHFUpload({ name, label, required, ...other }) {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...field }, fieldState: { error } }) => (
        <Form.Item label={null} required={required} validateStatus={!!error && "error"} help={error?.message}>
          {required && <span style={{ marginRight: "5px" }}>*</span>}
          <span style={{ fontSize: "14px", marginRight: "10px" }}>{`${label} : `}</span>
          <Upload {...field} {...other} innerRef={ref} />
        </Form.Item>
      )}
    />
  )
}

RHFUpload.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool
}
