import { createElement } from "react"
import PropTypes from "prop-types"
import { Avatar, Layout, Space, Dropdown, Button } from "antd"
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  LogoutOutlined,
  CaretDownOutlined
} from "@ant-design/icons"
import styled from "styled-components"
import useAuth from "hooks/useAuth"
import useLocales from "hooks/useLocales"

const { Header } = Layout

const Toolbar = styled.div({
  display: "flex",
  width: "100%",
  alignItems: "center",
  position: "relative",
  minHeight: "56px",
  height: "100%"
})

const Account = styled(Space)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-end",
  flexGrow: 1,
  cursor: "pointer"
})

const ButtonContainer = styled(Space)({})

export default function MainHeader({ collapsed, onCollapsed }) {
  const { user, logout } = useAuth()
  const { onChangeLang, translate, currentLang, allLang } = useLocales()

  const handleLogout = async (data) => {
    try {
      await logout(data)
    } catch (error) {
      console.log(error)
    }
  }
  const items = [
    {
      label: (
        <>
          <LogoutOutlined style={{ marginRight: "10px" }} />
          {translate("l.logout")}
        </>
      ),
      key: "1",
      onClick: handleLogout
    }
  ]

  const language = allLang.map((item) => {
    return {
      label: item.label,
      icon: item.icon,
      onClick: () => onChangeLang(item.value)
    }
  })

  const item = language

  return (
    <Header>
      <Toolbar>
        {createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
          onClick: onCollapsed
        })}
        <Account>
          <Dropdown
            menu={{
              items: item
            }}
            trigger={["click"]}
          >
            <Button type="text" style={{ marginTop: "15px", lineHeight: "15px" }}>
              <ButtonContainer>
                {currentLang.icon}
                {`${currentLang.label}`}
                <CaretDownOutlined />
              </ButtonContainer>
            </Button>
          </Dropdown>
          <Dropdown
            menu={{
              items
            }}
            trigger={["click"]}
          >
            <div className="account-user">
              <Avatar className="account-user-avatar" icon={<UserOutlined />} />
              <span className="account-user-name">{`${user?.firstname} ${user?.lastname}`}</span>
            </div>
          </Dropdown>
        </Account>
      </Toolbar>
    </Header>
  )
}

MainHeader.propTypes = {
  collapsed: PropTypes.bool,
  onCollapsed: PropTypes.func
}
