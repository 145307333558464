import styled from "styled-components"
import Spinner from "./Spinner"

const RootStyle = styled.div({
  right: 0,
  bottom: 0,
  zIndex: 99999,
  width: "100%",
  height: "100%",
  position: "fixed",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#00000005"
})

export default function LoadingPage() {
  return (
    <RootStyle>
      <Spinner />
    </RootStyle>
  )
}
