// Library
import PropTypes from "prop-types"
import { Spin } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import useLocales from "hooks/useLocales"

export default function Spinner({ tips, children, ...other }) {
  const { translate } = useLocales()

  return (
    <Spin
      size="large"
      tip={tips || `${translate("l.loading")}...`}
      indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
      {...other}
    >
      {children}
    </Spin>
  )
}

Spinner.propTypes = {
  children: PropTypes.node
}
