import PropTypes from "prop-types"
import { useFormContext, Controller } from "react-hook-form"
import { createElement } from "react"
import styled from "styled-components"
import { Input as AntdInput, Form as AntdForm, theme } from "antd"
import FloatInput from "./components/FloatInput"

const { useToken } = theme

const Input = styled(AntdInput)(({ token: { Input, colorDisabledBg, colorDisabledText } }) => ({
  font: "inherit",
  letterSpacing: "inherit",
  color: "currentcolor",
  border: "none !important",
  boxSizing: "content-box",
  background: "none",
  lineHeight: Input.lineHeight,
  margin: "0px",
  minWidth: "0px",
  width: "100%",
  fontSize: "14px",
  animationDuration: "10ms",
  backgroundColor: "#FFF",
  boxShadow: "none",
  ":focus": {
    boxShadow: "none !important",
    borderColor: "transparent !important",
    border: "none !important",
    borderInlineEndWidth: "0px !important"
  },
  ":disabled": {
    backgroundColor: `${colorDisabledBg} !important`,
    color: `${colorDisabledText} !important`
  }
}))

const InputPassword = styled(AntdInput.Password)({
  font: "inherit",
  letterSpacing: "inherit",
  color: "currentcolor",
  border: "0px",
  boxSizing: "content-box",
  background: "none",
  lineHeight: "2.5",
  margin: "0px",
  minWidth: "0px",
  width: "100%",
  animationDuration: "10ms",
  backgroundColor: "#FFF",
  ":focus": {
    boxShadow: "none !important",
    borderColor: "transparent !important",
    border: "none !important",
    borderInlineEndWidth: "0px !important"
  }
})

const Form = styled(AntdForm.Item)({
  marginBottom: "16px !important"
})
export default function RHFInput({ name, placeholder, required, type, size = "middle", prefix, ...other }) {
  const { control } = useFormContext()
  const { token } = useToken()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Form required={required} validateStatus={!!error && "error"} help={error?.message}>
          <FloatInput
            required={required}
            placeholder={placeholder}
            value={field.value}
            name={name}
            prefix={prefix}
            error={!!error}
            size={size}
          >
            {createElement(type === "password" ? InputPassword : Input, {
              size,
              token,
              prefix,
              ...field,
              ...other
            })}
          </FloatInput>
        </Form>
      )}
    />
  )
}

RHFInput.propTypes = {
  name: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  size: PropTypes.string,
  prefix: PropTypes.node,
  placeholder: PropTypes.string
}
