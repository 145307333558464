import { createContext, useState } from "react"
import Proptypes from "prop-types"

const initialState = {
  isCollapse: false,
  onToggle: () => {}
}

const CollapseContext = createContext(initialState)

function CollapseProvider({ children }) {
  const [isCollapse, setIsCollapse] = useState(false)

  const handleToggle = () => {
    setIsCollapse((prev) => !prev)
  }

  return (
    <CollapseContext.Provider
      value={{
        isCollapse,
        onToggle: handleToggle
      }}
    >
      {children}
    </CollapseContext.Provider>
  )
}

CollapseProvider.propTypes = {
  children: Proptypes.node
}

export { CollapseProvider, CollapseContext }
