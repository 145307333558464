import { forwardRef } from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet-async"

const Page = forwardRef(({ children, title = "", meta, ...other }, ref) => (
  <div style={{}}>
    <Helmet>
      <title>{`${title} | Cloudtime Passenger`}</title>
      {meta}
    </Helmet>

    <div ref={ref} {...other}>
      {children}
    </div>
  </div>
))

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  meta: PropTypes.node
}

export default Page
