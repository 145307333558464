import axios from "axios"
import { HOST_API } from "configs"

const axiosInstance = axios.create({
  baseURL: HOST_API
})

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error.response || "Something went wrong")
)

export default axiosInstance
